import React from "react"
import {
    PricingAboveFooterSection,
    PricingContactUsSection, PricingHeroSection,
    PricingIncludedSection,
} from "@/layout"

const PricingPage: React.FC = () => {
    return (
        <div className={'container-pricing'}>
            <PricingHeroSection/>
            <PricingContactUsSection/>
            <PricingIncludedSection/>
            <PricingAboveFooterSection/>
        </div>
    )
}

export default PricingPage
